import React from 'react'
import Position from '../../../components/Position';
import Highlight from '../../../components/Highlight';
import {
    Row,
    Space,
    Center,
    Container,
    TwoColumns,
    InfoContainer,
    ContainerFluid,
    ImageContainer
} from '../../../GlobalStyles';
import { StaticImage } from "gatsby-plugin-image";

import useI18n from '../../../i18n/useI18n';

const Description = ({ location }) => {
    
    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'All operational',
        es: 'Todas las funciones'
    }    
    const title = {
        en: 'functions in one place',
        es: 'operacionales en el mismo lugar'
    }

    return (
        <ContainerFluid>
            <Container>
                <Space />
                <Position location={location} />
                <Center>
                    <h2 data-sal="slide-up" data-sal-easing="ease-in-circ">
                        <Highlight text={translateObject(highlightTitle)}/> {translateObject(title)}
                    </h2>
                    <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay=".2s">
                        {translateObject({
                            en: `Admin Site is the basic tool with which you register the general profile of your business and that makes everything else works as a perfect machine.`,
                            es: `Admin Site es la herramienta básica con la que registras el perfil general de tu negocio y que hace que todo lo demás funcione como una máquina perfecta.`
                        })}
                        
                    </p>
                </Center>
            </Container>
            <TwoColumns>
                <Row>
                    <Container className='nested'>
                        <InfoContainer>
                            <div data-sal='slide-left' data-sal-easing="ease-in-circ">
                                <h3>
                                    {translateObject({
                                        en: `General operation management`,
                                        es: `Gestión general de operaciones`
                                    })}
                                </h3>
                                <p>
                                    {translateObject({
                                        en: `Transfer the structure of your business to the Admin Site. Manage the general operating data of your business, 
                                        create divisions or areas, register your staff and assign them the roles they have, 
                                        as well as the permissions they will have when using all the other tools.`,
                                        es: `Transfiere la estructura de tu negocio a Admin Site. Gestionar los datos operativos generales de tu negocio,
                                        crea divisiones o áreas, registra a su personal, asigna los roles que tienen,
                                        y los permisos que tendrán al usar todas las demás herramientas.`
                                    })}
                                </p>
                            </div>

                            <div data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-delay='300'>
                                <h3>
                                    {translateObject({
                                        en: `Control who logs into the tools`,
                                        es: `Controla quién tiene acceso a las herramientas`
                                    })}
                                </h3>
                                <p>
                                    {translateObject({
                                        en: `Create permissions and assign them to your staff according to the functions they are going to perform, 
                                        in this way you will guarantee that they will only have access to the tools and functions that correspond to them, 
                                        taking care of the security of your administrative information.`,
                                        es: `Crea permisos y asígnaselos a tu personal de acuerdo a las funciones que van a realizar, 
                                        de esta manera garantizarás que solo tendrán acceso a las herramientas y funciones que les correspondan 
                                        cuidando la seguridad de tu información administrativa.`
                                    })}
                                </p>
                            </div>
                        </InfoContainer>
                    </Container>
                </Row>
                <ImageContainer data-sal='slide-right' data-sal-easing="ease-in-circ" data-sal-duration='1000'>
                    <StaticImage
                        src="../../../images/adminsite-1.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="adminsite-1"
                        style={{ width: '100%', marginBottom: '0' }}
                        className='mask-container'
                        imgClassName='img-fluid'
                    />
                </ImageContainer>
            </TwoColumns>
        </ContainerFluid>
    )
};

export default Description;