import React from "react";
import Seo from "../components/seo";
import ProductLayout from "../containers/ProductLayout";
import { Section } from '../GlobalStyles.jsx';
import { graphql, useStaticQuery } from "gatsby";
import '../globalStyles.css';
import { BG_DARK, BG } from '@etiquette-ui/colors';
import Description from "../sections/Products/AdminSite/description";
import SecondSection from "../sections/Products/AdminSite/second-section";


const AdminSite = () => {
  const dataquery = useStaticQuery(query);
  const datainfo = dataquery.allFile.edges;

  const data = {
    currentPage: 0,
    title: 'Admin Site',
    heroLogo: datainfo[0].node.childrenImageSharp[0].fluid,
    heroAltLogo: datainfo[0].node.base,
    heroImage: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAlt: datainfo[3].node.base,
    heroMobile: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAltMobile: datainfo[3].node.base,
    banerImg: datainfo[1].node.childrenImageSharp[0].fluid,
    banerAlt: datainfo[1].node.base,
    banerImgMobile: datainfo[1].node.childrenImageSharp[0].fluid,
    banerImgAltMobile: datainfo[1].node.base,
    features: [
      {
        en: 'Create and manage the different restaurants of a group',
        en: 'Crea y administra los diferentes restaurantes del grupo'
      },
      {
        en: 'Create and manage authorized users and permissions',
        en: 'Crea y administra cuentas de usuarios y sus permisos'
      },
      {
        en: 'Create and manage the different applications that will have access to one of the various applications available',
        en: 'Crea y administra los permisos de acceso y uso de las diferentes aplicaciones disponibles'
      },
      {
        en: 'Create administrative roles and have different roles',
        en: 'Crea perfiles adminstrativos con diferentes permisos'
      },
      {
        en: 'Create the administrative sections that we have available',
        en: 'Utiliza las secciones administrativas que tenemos disponibles'
      },
      {
        en: 'Create email template that can be shared with other applications',
        en: 'Crea plantillas de correo que se pueden compartir entre las otras aplicaciones'
      },
      {
        en: 'Look at the distant charts for sales and sales adjustments',
        en: 'Monitorea las gráficas de ventas de forma remota'
      },
    ],
  };

  return (
    <ProductLayout data={data} >
      <Seo title={data.title} />

      <Section color={BG}>
        <Description location={data.title} />
      </Section>
      <Section color={BG_DARK}>
        <SecondSection />
      </Section>
    </ProductLayout >
  );
};
export default AdminSite;

const query = graphql`
query adminsite {
    allFile(
      filter: {extension: {regex: "/(png)/"}, 
        name: {in: [
          "adminsite-hero",
          "adminsite-icon",
          "adminsite-banner",
          "digitalmenu-hero-mobile",
          "digitalmenu-banner-mobile"
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }  
`;