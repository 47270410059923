import React from 'react'
import Highlight from '../../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image";
import { ThreeColumns } from '../../../GlobalStyles';

import useI18n from '../../../i18n/useI18n';

const SecondSection = () => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Keep your',
        es: 'Mantén actualizada'
    }    
    const title = {
        en: 'staff members data up-to-date',
        es: 'la información de tu equipo'
    }

    return (
        <ThreeColumns className='fluid'>
            <div className='col-1'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='700'>
                <StaticImage
                    src="../../../images/adminsite-2.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="adminsite-2"
                    className="img-container overflow-fluid-md"
                    imgClassName="overflowed-img"
                />
            </div>

            <div className='center'data-sal="slide-up" data-sal-easing="ease-in-circ">
                <h2><Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}</h2>
                <p>
                    {translateObject({
                        en: `All businesses have moments in which they need to register, cancel or modify personal 
                        and work information of their staff, easily update this information within the Admin Site 
                        and have it always present when you need it.`,
                        es: `Todos los negocios tienen momentos en los que necesitan dar de alta, cancelar o modificar 
                        información personal y laboral de su personal, actualiza fácilmente esta información dentro de 
                        Admin Site y tenla siempre presente cuando la necesites.`
                    })}
                </p>
            </div>

            <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-duration='700'>
                <StaticImage
                    src="../../../images/adminsite-3.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="adminsite-3"
                    className='img-container'
                    imgClassName='overflowed-img'
                />
            </div>
        </ThreeColumns>
    )
};

export default SecondSection;